<template>
  <el-container class="app-container">
    <el-header style="margin:10px 0px;font-size:20px;">{{ $t('page.BasicInformation') }}</el-header>
    <!-- <el-main style=""> -->
    <el-row class="row-center">
      <el-col :span="10"><div class="grid-content" />
        <el-form ref="ruleForm" :disabled="fordisabled" :label-position="labelPosition" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
          <el-form-item label="FNSKU/UPC" prop="sku">
            <el-input v-model="ruleForm.sku" :placeholder="$t('page.inputPlaceholder')" @blur="skublur" />
          </el-form-item>
          <el-form-item label="STYLE" prop="style">
            <el-select v-model="ruleForm.style" value-key="id" clearable filterable :placeholder="$t('page.selectPlaceholder')" @change="styleSelectChange">
              <el-option
                v-for="item in styleOptions"
                :key="item.id"
                :label="item.styleName"
                :value="item.styleName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="COLOR" prop="color">
            <el-select v-model="ruleForm.color" clearable filterable :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in colorOptions"
                :key="item.id"
                :label="item.colorName"
                :value="item.colorName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="SIZE" prop="size">
            <el-select v-model="ruleForm.size" filterable clearable :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in sizeOptions"
                :key="item.id"
                :label="item.size"
                :value="item.size"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="供应商" prop="vendorId">
            <el-select v-model="ruleForm.vendorId" clearable filterable :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in vendorOptions"
                :key="item.id"
                :label="item.vendorName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="含税价格" prop="purchasePrice">
            <el-input v-model="ruleForm.purchasePrice" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="不含税价格" prop="excludingTaxPrice">
            <el-input v-model="ruleForm.excludingTaxPrice" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="报价币种" prop="currencyType">
            <el-select v-model="ruleForm.currencyType" :placeholder="$t('page.selectPlaceholder')">
              <!-- <el-option label="美元" value="1" />
              <el-option label="人民币" value="2" /> -->
              <el-option v-for="item in _getAllCommodityDict('CURRENCY_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否含税" prop="isExcludingTax">
            <el-input v-model="ruleForm.isExcludingTax" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="美元单价" prop="isExcludingTax">
            <el-input v-model="ruleForm.dollarPrice" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="状态" prop="useStatus">
            <el-radio-group v-model="ruleForm.purchaseStatus">
              <el-radio :label="0">{{ $t('page.enabled') }}</el-radio>
              <el-radio :label="1">{{ $t('page.disable') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row class="grid-content" type="flex" justify="center">
            <el-button type="primary" style="width:100px" :loading="Loading" @click="handlesubmitForm('ruleForm')">{{ $t('page.submit') }}</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- </el-main> -->
  </el-container>
</template>

<script>
import { getpriceid, getSkuInfo, queryStyleList, querySizeList, queryColorList, queryVendorList, addpurchaseprice, modifypurchaseprice } from '@/api/scm-api'
// import { number } from 'echarts/lib/export'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  mixins: [commodityInfoDict],

  data() {
    const purchasePrices = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(this.$t('page.required')))
      } else {
        const reg = /^([0-9]{1,6}(\.\d{0,6}[0-9])?)$|^(0\.\d{0,6}[0-9])$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error(this.$t('page.digits16')))
        }
      }
    }
    const excludingTaxPrices = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(this.$t('page.required')))
      } else {
        const reg = /^([0-9]{1,6}(\.\d{0,6}[0-9])?)$|^(0\.\d{0,6}[0-9])$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error(this.$t('page.digits16')))
        }
      }
    }
    return {
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      vendorOptions: [],
      fordisabled: false, // 禁用文本框
      data: [],
      labelPosition: 'right',
      categoryLevel: 1,
      Loading: false,
      ruleForm: {
        sku: '',
        color: '',
        purchasePrice: '',
        excludingTaxPrice: '',
        purchaseStatus: 0,
        currencyType: '',
        size: '',
        style: '',
        vendorId: ''
      },
      fnsku: '',
      upc: '',
      skublurupc: '',
      skublurfnsku: '',
      rules: {
        sku: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        style: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        color: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        size: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        vendorId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        purchasePrice: [{ required: true, validator: purchasePrices, trigger: 'blur' }],
        excludingTaxPrice: [{ required: true, validator: excludingTaxPrices, trigger: 'blur' }],
        currencyType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        purchaseStatus: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      }
    }
  },
  computed: {
    // 获取输入框的值
    formParams() {
      const { sku, color, size, purchasePrice, excludingTaxPrice, purchaseStatus, style, vendorId } = this.ruleForm
      return Object.assign({}, { sku, color, size, purchasePrice, excludingTaxPrice, purchaseStatus, style, vendorId })
    },
    // 生成对应的请求参数
    Parameter() {
      return Object.assign({}, this.formParams)
    }
  },
  mounted() {
    // 查看时 禁用for
    if (this.$route.query && this.$route.query.disabled) {
      this.fordisabled = true
    }
    this._queryColorList()
    this._querySizeList()
    this._queryStyleList()
    this._queryVendorList()
    this._getpriceid()
  },
  methods: {
    skublur() {
      this._getSkuInfo({ sku: this.ruleForm.sku })
    },
    async _getSkuInfo(sku) {
      const { datas } = await getSkuInfo(sku)
      this.ruleForm.sku = datas.sku
      this.ruleForm.style = datas.style
      this.ruleForm.color = datas.color
      this.ruleForm.size = datas.size
      this.ruleForm.vendorId = datas.vendors[0].id
      this.skublurupc = datas.upc
      this.skublurfnsku = datas.fnsku
    },
    // 数据回显
    async _getpriceid(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await getpriceid(id)
        this.ruleForm = datas
        this.ruleForm.purchasePrice = Number(datas.purchasePrice)
        this.ruleForm.excludingTaxPrice = Number(datas.excludingTaxPrice)
        this.ruleForm.currencyType = String(datas.currencyType)
        this.ruleForm.purchaseStatus = Number(datas.purchaseStatus)

        console.log(datas.fnsku)
        console.log(datas.upc)
        this.fnsku = datas.fnsku
        this.upc = datas.upc
      }
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.styleName === value)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // 点击提交按钮将表单数据提交至后台
    handlesubmitForm(query) {
      var data = {}
      data.sku = this.ruleForm.sku
      data.style = this.ruleForm.style
      data.color = this.ruleForm.color
      data.size = this.ruleForm.size
      data.vendorId = this.ruleForm.vendorId
      data.purchaseStatus = this.ruleForm.purchaseStatus
      data.purchasePrice = this.ruleForm.purchasePrice
      data.excludingTaxPrice = this.ruleForm.excludingTaxPrice
      data.currencyType = this.ruleForm.currencyType
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return false
        const { id } = this.$route.query
        if (id) {
          data.id = id
          data.upc = this.upc
          data.fnsku = this.fnsku
          this._modifypurchaseprice(data)
        } else {
          data.upc = this.skublurupc
          data.fnsku = this.skublurfnsku
          this._addpurchaseprice(data)
        }
      })
    },
    async _addpurchaseprice(query) {
      try {
        this.Loading = true
        const { code, msg } = await addpurchaseprice(query)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.Loading = false
        }
      } finally {
        this.Loading = false
      }
    },
    // 修改采购价格
    async _modifypurchaseprice(query) {
      const { code, msg } = await modifypurchaseprice(query)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.$router.push({
          path: '../',
          append: true
        })
      } else { // 其他状态
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-input--small .el-input__inner {
    width: 235px;
}
</style>
